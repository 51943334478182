export const starPath =
  "M93.658,7.186,68.441,60.6,12.022,69.2C1.9,70.731-2.15,83.763,5.187,91.227l40.818,41.557-9.654,58.7c-1.738,10.611,8.959,18.559,17.918,13.6l50.472-27.718,50.472,27.718c8.959,4.922,19.656-2.986,17.918-13.6l-9.654-58.7,40.818-41.557c7.337-7.464,3.282-20.5-6.835-22.029L141.04,60.6,115.824,7.186A12.139,12.139,0,0,0,93.658,7.186Z";

export const VAPIDKEY =
  "BBAS6jVsiEE86EtJvP9RGSkPt46szXb2Ao7pfUdOL0xhhDiiPGnzgwN3utpw_O6RFMbuxgui2d3F7W98jFB5ZWk";

export const agoraAppID = "320f1867bc3d4922b4da5963a9f2b760";

export const PUSHY_SECRET_API_KEY =
  "73572a9672a97ef4af6e38167d99d87825d53642114ff16780f3082f9973c16f";

export const PUSHY_APP_ID =
  "6155cb14fbd979fe468eab32";

export const FCM_AUTH_HEADER =
  "key=AAAAmhuAeFI:APA91bHSnIpAbEKtDTjvZH1F3xtJvHIlz_m1GOOgzbtZdsiqXpUk7QZvVdKs_9PIbnENdz_ytxaEjFqihGA9MZsW7S6FS1WsOOTDfawNdW_6iVbeduNZOxMVwfEzM69QpHsEVouUQFyd";

export const firebaseAPiKey = "AIzaSyCgfeFcXVvvuIp79IJD8KCahJo2PzrHDco";

export const appGooglePlayLink =
  "https://play.google.com/store/apps/details?id=com.pustack.android.pustack&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";

export const appGooglePlayTutorLink = "https://play.google.com/store/apps/details?id=com.pustack.android.pustacktutor";

export const appAppleLink = "https://apps.apple.com/app/pustack/id6444080075";

export const appAppleTutorLink = "https://apps.apple.com/in/app/pustack-tutor/id6444847124";

export const googlePlayBadge =
  "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png";

export const firebaseDbURL = "https://avian-display-193502.firebaseio.com";

export const firebaseFunctionBaseURL = "https://us-central1-avian-display-193502.cloudfunctions.net/";

export const miniChartData = [
  {
    date: 1,
    hours: 1,
    sessions: 15,
    earnings: 400,
  },
  {
    date: 2,
    hours: 2,
    sessions: 20,
    earnings: 800,
  },
  {
    date: 3,
    hours: 2.5,
    sessions: 15,
    earnings: 600,
  },
  {
    date: 4,
    hours: 3,
    sessions: 12,
    earnings: 480,
  },
  {
    date: 5,
    hours: 2,
    sessions: 20,
    earnings: 800,
  },
  {
    date: 6,
    hours: 2,
    sessions: 25,
    earnings: 1000,
  },
  {
    date: 7,
    hours: 2.5,
    sessions: 30,
    earnings: 1200,
  },
  {
    date: 8,
    hours: 3,
    sessions: 25,
    earnings: 1000,
  },
  {
    date: 9,
    hours: 2,
    sessions: 20,
    earnings: 800,
  },
  {
    date: 10,
    hours: 2,
    sessions: 20,
    earnings: 800,
  },
  {
    date: 11,
    hours: 2,
    sessions: 25,
    earnings: 1000,
  },
  {
    date: 12,
    hours: 2.5,
    sessions: 30,
    earnings: 1200,
  },
  {
    date: 13,
    hours: 3,
    sessions: 25,
    earnings: 1000,
  },
  {
    date: 14,
    hours: 3,
    sessions: 15,
    earnings: 600,
  },
  {
    date: 15,
    hours: 3,
    sessions: 16,
    earnings: 640,
  },
  {
    date: 16,
    hours: 2,
    sessions: 20,
    earnings: 1000,
  },
  {
    date: 17,
    hours: 2,
    sessions: 22,
    earnings: 880,
  },
  {
    date: 18,
    hours: 3,
    sessions: 35,
    earnings: 1200,
  },
  {
    date: 19,
    hours: 3.5,
    sessions: 38,
    earnings: 1500,
  },
];
